import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// CSS
import 'styles/Contact.scss'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import GravityForm from 'components/GravityForm'
import { BannerDefault, ButtonAlt } from 'components/Elements'
import CustomLink from 'components/CustomLink'
import Modal from 'components/Modal'

// Third Party
import _ from 'lodash'

// Images
import romazo from 'img/romazo.jpg'

function Contact() {
  const {
    contact: {
      acf: {
        page_content: { content: contactContent },
        opening_times: openingTimes,
        common_info: commonInfo,
      },
      yoast_meta: yoast,
    },
  } = useStaticQuery(graphql`
    {
      contact: wordpressPage(wordpress_id: { eq: 17 }) {
        acf {
          page_content {
            content
          }
          opening_times {
            day
            time
          }
          common_info {
            btw_number
            common_address
            common_city
            company_mail
            company_name
            kvk_number
            phonenumber
          }
        }
        yoast_meta {
          name
          content
          property
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO yoast={{ meta: yoast }} />
      <div className="contact">
        <iframe
          title="google-maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2443.7964744668525!2d5.361201116160054!3d52.22891537976011!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c640b020e5effd%3A0x5b3970902352207!2sDe%20Kronkels%2020%2C%203752%20LM%20Bunschoten-Spakenburg!5e0!3m2!1snl!2snl!4v1578565700457!5m2!1snl!2snl"
          width="100%"
          height="350"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen=""
        />
        <BannerDefault className="color-background-main py-4">
          <h2 className="w-100 text-center font-size-xl font-weight-xl">
            Advies nodig? Wij helpen u graag verder
          </h2>
        </BannerDefault>
        <div className="container py-5">
          <div className="row pb-5">
            <div className="col-12 col-lg-6 px-3 pb-3 d-flex flex-wrap justify-content-start">
              <div
                className="d-flex flex-wrap pb-5"
                dangerouslySetInnerHTML={{ __html: contactContent }}
              />
              <div className="w-100">
                <h2 className="yellow-underline float-left pb-1 mb-3 font-size-xl font-weight-xl">
                  Ons adres
                </h2>
              </div>
              <p className="m-0 w-100">{commonInfo.common_address}</p>
              <p className="m-0 w-100">{commonInfo.common_city}</p>
              <br />
              <a
                className="m-0 pt-3 w-100"
                href={`tel:${commonInfo.phonenumber}`}
              >{`T: ${commonInfo.phonenumber}`}</a>
              <br />
              <a
                className="m-0 w-100"
                href={`mailto:${commonInfo.company_mail}`}
              >{`E: ${commonInfo.company_mail}`}</a>
              <div className="w-100 mt-5">
                <h2 className="yellow-underline float-left pt-3 pb-1 mb-3 font-size-xl font-weight-xl">
                  Showroom
                </h2>
              </div>

              {/* [start] Temp fix voor COVID */}
              {/* {_.map(openingTimes, time => (
                <p className="mb-1 w-100">{`${time.day} : ${time.time}`}</p>
              ))} */}
              <p className="m-0 mb-4 w-100">
                De showroom is geopend op afspraak. Een afspraak maken kan{' '}
                <CustomLink to="/showroomafspraak-maken">online</CustomLink> of{' '}
                telefonisch via <a href="tel:033-299 99 22">033-299 99 22</a>
              </p>
              <br />
              <br />
              <ButtonAlt to="/showroomafspraak-maken">
                Maak een afspraak
              </ButtonAlt>
              {/* [stop] Temp fix voor COVID */}

              {/* <h2 className="yellow-underline pt-3 pb-1 mb-3 font-size-xl font-weight-xl">Onze showroom is geopend</h2> */}
              <p className="m-0  w-100">
                {/* [start] Temp fix voor COVID */}
                {/* <span>Wij vragen u voorafgaand aan uw bezoek* een afspraak te maken, dat kan vrijblijvend via:</span> */}
                {/* [stop] Temp fix voor COVID */}

                <br />
                <br />
                <a href="tel:033-299 99 22">
                  <span className="font-weight-xl">T:</span> 033-299 99 22
                </a>
                <br />
                <a href="mailto:info@koolooszonenhuis.nl">
                  <span className="font-weight-xl">E:</span>{' '}
                  info@koolooszonenhuis.nl
                </a>
                <br />
                <br />
              </p>

              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.romazo.nl/romazogarant-keurmerk"
              >
                <img className="romazo-image" src={romazo} alt="" />
              </a>
            </div>
            <div className="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end">
              <div className="form-wrapper p-3">
                <GravityForm id={1} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Modal /> */}
    </Layout>
  )
}

export default Contact
